import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

// Bootstrap
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const ProductCard = ({
  productImage,
  productTitle,
  productPrice,
  productSlug,
  isInStock,
}) => (
  <Col lg={3} md={4} sm={6} className="d-flex align-items-stretch">
    <Card className="mb-4 w-100 product-card">
      <Card.Img
        as={Img}
        fluid={productImage}
        alt={productTitle}
        variant="top"
        className="product-card-img img-fluid"
      />
      <Card.Body className="product-card-body">
        <Card.Title
          className="product-card-body-title gradient-text"
          as={Link}
          to={`${productSlug}/`}
        >
          {productTitle}
        </Card.Title>
        <Card.Text className="product-card-bottom">
          <span className="product-card-price">
            {productPrice.toFixed(0)} грн
          </span>
          {isInStock ? (
            <Button
              as={Link}
              variant="outline-newtele"
              to={`${productSlug}/`}
              className="mt-3"
            >
              Купити
            </Button>
          ) : (
            <Button
              as={Link}
              variant="outline-secondary"
              to={`${productSlug}/`}
              className="mt-3"
            >
              Переглянути
            </Button>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
)

ProductCard.defaultProps = {
  productImage: `none`,
  productTitle: `название товара`,
  productPrice: `0.00 грн`,
  productSlug: `/`,
  isInStock: true,
}

export default ProductCard
