import React from "react"
import { graphql, Link } from "gatsby"
// import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Nav from "react-bootstrap/Nav"

import ProductCard from "../components/shop/ProductCard"

export const query = graphql`
  query($slug: String!) {
    category: contentfulShopCategory(seo: { slug: { eq: $slug } }) {
      id
      categoryName
      seo {
        title
        metaDescription
        slug
        pageImage {
          fluid(maxWidth: 620) {
            src
          }
        }
      }
    }
    categories: allContentfulShopCategory(
      filter: { node_locale: { eq: "uk" }, isActive: { eq: true } }
    ) {
      nodes {
        id
        categoryName
        categoryImage {
          id
          fixed {
            src
          }
        }
        categoryDescription {
          content {
            content {
              value
            }
          }
        }
        seo {
          slug
          title
          metaDescription
          pageImage {
            fixed {
              src
            }
          }
        }
      }
    }
    products: allContentfulShopProduct(
      filter: {
        node_locale: { eq: "uk" }
        productCategory: { seo: { slug: { eq: $slug } } }
        isActive: { eq: true }
      }
    ) {
      nodes {
        id
        productCategory {
          categoryName
          seo {
            slug
          }
        }
        isActive
        isInStock
        productName
        productPrice
        seo {
          slug
        }
        productDescription {
          json
        }
        productImage {
          fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: PAD) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    image: contentfulAsset(id: { eq: "72cccbe9-87ae-5a4e-a9ef-a2063d504c4e" }) {
      title
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

const CategoryTemplate = ({
  data: { categories, category, products, image },
}) => {
  return (
    <Layout>
      <SEO
        title={`Купити ${category.seo.title}, ціна, відгуки, продаж`}
        description={category.seo.metaDescription}
        image={`https:${category.seo.pageImage.fluid.src}`}
        url={`/${category.seo.slug}/`}
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/shop/">Обладнання</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {category.categoryName}
              </li>
            </ol>
          </nav>
          <h1 className="gradient-text">{category.categoryName}</h1>
          <div className="hero-slogan mb-5"> </div>
        </Container>
      </div>
      <Container className="my-5">
        <Nav as="ul" className="mb-5 shop-categories-filters">
          <Nav.Item>
            <Nav.Link as={Link} to="/shop/">
              Все обладнання
            </Nav.Link>
          </Nav.Item>
          {categories.nodes.map(cat => (
            <Nav.Item key={cat.id}>
              {cat.seo.slug === category.seo.slug ? (
                <Nav.Link disabled>{cat.categoryName}</Nav.Link>
              ) : (
                <Nav.Link as={Link} to={`/${cat.seo.slug}/`}>
                  {cat.categoryName}
                </Nav.Link>
              )}
            </Nav.Item>
          ))}
        </Nav>
        <Row>
          {products.nodes.map(product => (
            <ProductCard
              key={product.id}
              productTitle={product.productName}
              productPrice={product.productPrice}
              productImage={product.productImage.fluid}
              productSlug={`/${product.productCategory.seo.slug}/${product.seo.slug}`}
              isInStock={product.isInStock}
            />
          ))}
        </Row>
      </Container>
    </Layout>
  )
}

export default CategoryTemplate
